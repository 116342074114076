.menu-item {
    height: 55px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: larger;
    padding: 0 50px;
}

.menu-item:hover {
    background-color: rgba(0, 0, 0, 0.3);
}