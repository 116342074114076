.resume-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 25px auto;
    width: 90%;
}

.resume-container__warning {
    color: white;
    max-width: 100%;
    width: 600px;
    margin-bottom: 0;
}

.resume-document {
    margin-top: 10px;
    position: relative;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 30px 40px 0 rgb(0 0 0 / 50%);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.resume-page {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.resume-page > canvas {
    max-width: 100%;
    height: auto !important;
}

.resume-document:hover .page-controls {
    opacity: 100%;
}

.page-controls {
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
}

.page-controls span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
}

.page-controls button:enabled:hover {
    cursor: pointer;
}

.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
    background-color: #e6e6e6;
}

.page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.resume__download-btn {
    background-color: var(--secondary-color);
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
}

.resume__download-btn:hover {
    background-color: white;
    color: black;
}