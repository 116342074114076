.projects-header {
    height: 300px;

    text-align: center;
    font-size: 7ex;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    position: relative;
}

.projects-header-clouds {
    display: block;
    position: absolute;
    bottom: 0;
}

.projects-content {
    max-width: 1200px;
    margin: 50px auto;
}

.projects-list {
    --grid-layout-gap: 1rem;
    --grid-column-count: 2;
    --grid-item--min-width: 240px;

    /**
     * Calculated values
     */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    gap: var(--grid-layout-gap);
    justify-items: center;
    row-gap: 75px;

    padding: 1rem;
}
