.hero {
    background-image: url("/src/assets/img/hero-background.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero__main-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    row-gap: 1rem;
    align-items: center;
    justify-items: center;
    color: white;
    padding: 1rem;
}

.hero__photo {
    display: block;
    border-radius: 50%;
    width: 350px;
    max-width: 100%;
    height: auto;
}


.hero__catchphrase {
    max-width: 100%;
    width: 450px;
}

.hero__title {
    font-weight: normal;
    font-size: xxx-large;
}

.hero__subtitle {
    font-size: larger;
}

.hero__title--bold {
    font-weight: bold;
}

.hero__socials {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    color: #047585;
}

.hero__socials--link {
    color: inherit;
}

.hero__socials--link:hover {
    color: white;
}