.skill-section {
    box-shadow: 0 -0.5px 50px 5px rgba(0, 0, 0, 0.5);
}

.landing-section__title {
    max-width: 1200px;
    margin: 0 auto;
}

.landing-section__description {
    color: white;
    max-width: 1200px;
    margin: 0 auto;
}

.landing-section__description a {
    text-decoration: underline;
}

.cards-container {
    --grid-layout-gap: 25px;
    --grid-column-count: 2;
    --grid-item--min-width: 240px;

    /**
     * Calculated values
     */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
    justify-items: center;

    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.card {
    max-width: 360px;
    height: 250px;
}

.timeline-item ul {
    list-style: circle inside;
}

