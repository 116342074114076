.header {
    width: 100%;
    position: relative;
    z-index: 10;
}

.header--color-primary {
    background-color: var(--primary-color);
}

.header--color-transparent {
    background-color: rgba(255, 255, 255, 5%);
}

.header--position-absolute {
    position: absolute;
}

.header__navbar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

a {
    text-decoration: none;
    color: white;
}

.header__nav-mobile {
    height: 55px;
}

.header__nav-mobile--background {
    background-color: var(--primary-color);
}

.header__nav-mobile-btn {
    color: white;
    background-color: transparent;
    border: none;
    height: 100%;
    width: 50px;
    cursor: pointer;
    transition: color 0.1s ease;
}

.header__nav-mobile-btn:hover {
    color: rgba(255, 255, 255, 0.2);
}

.header__nav-mobile-items {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--primary-color);
}