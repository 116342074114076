.landing-section {
    padding: 60px 0;
}

.landing-section__title {
    margin-top: 0;
    margin-left: 10px;
    font-size: 4ex;
    color: white;
    font-weight: normal;
}

.landing-section--color-primary {
    background-color: var(--primary-color);
}

.landing-section--color-secondary {
    background-color: var(--secondary-color);
}