.project-card {
    max-width: 400px;
    height: auto;
    background-color: white;
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    cursor: pointer;
    overflow: hidden;
}

.project-card__image {
    width: 100%;
    height: 235px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .2s;
}

.project-card__image:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75);
}

.project-card__image-title {
    display: none;
    font-size: 35px;
    color: #ffffff !important;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
}

.project-card:hover .project-card__image, .project-card:focus .project-card__image {
    transform: scale(1.1);
}

.project-card:hover .project-card__image-title,
.project-card:focus .project-card__image-title,
.project-card:hover .project-card__image:before,
.project-card:focus .project-card__image:before {
    display: block;
}

.project-card__content {
    padding: 20px 5px 15px 15px;
}

.project-card__title {
    margin: 0;
    font-weight: bold;
}

.project-card__description {
    color: rgba(0, 0, 0, 0.7);
}